import React, { useEffect, useState } from 'react'
import ServiceItem from '../service-item/service-item.component';

const ManageServiceContainer = ({ showModal, cmsServices }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [serviceCount, setServiceCount] = useState(0);

  const createReferences = () => {
    let i = 0;
    return cmsServices && cmsServices.map((service, index) => {
      i++;
      if (i < 5) {
        return <ServiceItem key={index} service={service} showModal={showModal} manageable={true}/>;
      } else {
        return (
          <ServiceItem key={index} service={service} showModal={showModal} manageable={true}/>
        );
      }
    });
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    setServiceCount(cmsServices.length);
    console.log(serviceCount);
  }, [cmsServices]);

  return (
    <>
      {cmsServices && createReferences()}
      <div className="flex mx-auto items-center justify-center w-[100%]">
      </div>
    </>
  );
};

export default ManageServiceContainer;
