import React, { useEffect } from 'react'
import './website-preview.styles.css'
import Logo from './assets/img/logo.png'
import LogoBlue from './assets/img/logo-blue.png'
import HeroImg from './assets/img/heroimg.svg'
import { useState } from 'react'
import Modal from '../../components/modal/modal.component'
import ReferenceContainer from '../../components/reference-container/reference-container.component'
import Review from '../../components/review/review.component'
import {Buffer} from 'buffer';
import ServiceContainer from '../../components/service-container/service-container.component'
import { useNotificationContext } from '../../context/notification.context'

function WebsitePreview({editMode}) {
    const [navBarActive, setNavBarActive] = useState(false);

    const [modalVisibility, setModalVisibility] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(true);

    const [modalTitel, setModalTitel] = useState("")
    const [modalText, setModalText] = useState("")
    const [modalList, setModalList] = useState([])
    const [modalImg, setModalImg] = useState(HeroImg)
    const [modalPrice, setModalPrice] = useState(0)

    const [formFullname, setFormFullname] = useState("")
    const [formMail, setFormMail] = useState("")
    const [formNumber, setFormNumber] = useState("")
    const [formMessage, setFormMessage] = useState("")
    const [formSuccess, setFormSuccess] = useState(null)

    const [cmsText, setCMSText] = useState([])
    const [cmsReferences, setCMSReferences] = useState([])
    const [cmsServices, setCMSServices] = useState([])

    const [scrollPosition, setScrollPosition] = useState(0)

    const { setNotification } = useNotificationContext()

    const getCMSElements = () => {
        let elements = document.querySelectorAll(".cmsText");
        let elementToAdd = {}
        elements.forEach(element => {
            elementToAdd[element.id] = element.innerHTML
        })
        console.log(elementToAdd)
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(elementToAdd)
        }
        // fetch('http://localhost:4111/api/cms/send/', requestOptions)
        fetch(process.env.REACT_APP_API_URL+'api/cms/send/', requestOptions)
            .then(response => {
                console.log(response)
                setNotification({
                    show: true,
                    color: 'green',
                    text: 'Textänderungen wurden erfolgreich gespeichert!'
                })
            })
            .catch(error => {
                setNotification({
                    show: true,
                    color: 'red',
                    text: 'Ein Fehler ist aufgetreten! Versuche es später erneut.'
                })
            })
    }

    const handleScroll = () => {
        const position = window.scrollY;
        setScrollPosition(position);
        let header = document.getElementById("header");
        let navaction = document.getElementById("navAction");
        let toToggle = document.querySelectorAll(".toggleColour");
        let navcontent = document.getElementById("nav-content");
        let navBurger = document.getElementById('navBurger')

        if (position > 10) {
            header.classList.add("bg-white");
            navaction.classList.remove("bg-white");
            navaction.classList.add("gradient");
            navaction.classList.remove("text-gray-800");
            navaction.classList.add("text-white");
            //Use to switch toggleColour colours
            for (var i = 0; i < toToggle.length; i++) {
                toToggle[i].classList.add("text-gray-800");
                toToggle[i].classList.remove("text-white");
            }
            header.classList.add("shadow");
            navcontent.classList.remove("bg-gray-100");
            navcontent.classList.add("bg-white");
            // logo_blue.classList.remove('collapse')
            // logo_white.classList.add('collapse')
            navBurger.classList.add('fill-blue-500')
            navBurger.classList.remove('fill-white')
        } else {
            header.classList.remove("bg-white");
            navaction.classList.remove("gradient");
            navaction.classList.add("bg-white");
            navaction.classList.remove("text-white");
            navaction.classList.add("text-gray-800");
            // logo_blue.classList.add('collapse')
            // logo_white.classList.remove('collapse')
            //Use to switch toggleColour colours
            for (var i = 0; i < toToggle.length; i++) {
                toToggle[i].classList.add("text-white");
                toToggle[i].classList.remove("text-gray-800");
            }
        
            header.classList.remove("shadow");
            navcontent.classList.remove("bg-white");
            navcontent.classList.add("bg-gray-100");
            navBurger.classList.remove('fill-blue-500')
            navBurger.classList.add('fill-white')
        }
    }

    const submitForm = (e) => {
        e.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                fullname: formFullname,
                email: formMail,
                phone: formNumber,
                message: formMessage
            })
        }
        fetch(process.env.REACT_APP_API_URL+'api/contact', requestOptions)
            .then(response => {
                console.log(response)
                setFormFullname("")
                setFormMail("")
                setFormNumber("")
                setFormMessage("")
                setFormSuccess(true)
            })
            .catch(error => {
                console.log(error)
            })
    }

    useEffect(() => {
        getCMS()
    }, [])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    
    })

    async function getCMS() {
        getReferences()
        getCMSText()
        getCMSImages()
        getServices()

        if (editMode) {
            var cmsTextElements = document.getElementsByClassName('cmsText');
            // Loop through each element and set contentEditable to true
            for (var i = 0; i < cmsTextElements.length; i++) {
                cmsTextElements[i].contentEditable = true;
}
        }
    }

    async function getReferences() {
        // fetch('http://localhost:4111/api/cms/get/references')
        fetch(process.env.REACT_APP_API_URL+'api/cms/get/references')
            .then(response => response.json())
            .then(data => {
                data.forEach(element => {
                    const base64string = Buffer.from(element.image_file.data, 'base64').toString('ascii')
                    element.image_file = `${base64string}`;
                })
                setCMSReferences(data)
            })
    }

    async function getServices() {
        // fetch('http://localhost:4111/api/cms/get/services')
        fetch(process.env.REACT_APP_API_URL+'api/cms/get/services')
            .then(response => response.json())
            .then(data => {
                data.forEach(element => {
                    const stringToArray = JSON.parse(element.list)
                    element.list = stringToArray
                })
                setCMSServices(data)
            })
    }

    async function getCMSText() {
        // fetch('http://localhost:4111/api/cms/get/')
        fetch(process.env.REACT_APP_API_URL+'api/cms/get/')
            .then(response => response.json())
            .then(data => {
                console.log('Das ist die Data: ', data)
                data.forEach(element => {
                    document.getElementById(element.id).innerHTML = element.text
                })
            })
    }

    async function getCMSImages() {
        // fetch('http://localhost:4111/api/cms/get/images')
        fetch(process.env.REACT_APP_API_URL+'api/cms/get/images')
            .then(response => response.json())
            .then(data => {
                console.log(data)
                data.forEach(element => {
                    try {
                    const base64string = Buffer.from(element.image.data, 'base64').toString('ascii')
                    document.getElementById(element.id).src = base64string
                    } catch (error) {
                        console.log(error, element)
                    }
                })
            })
    }

    const showModal = (titel, text, list, img, price) => {
        setModalImg(img)
        setModalTitel(titel)
        setModalText(text)
        setModalList(list)
        setModalVisibility(!modalVisibility)
        setModalPrice(price)
    }

    const toggleNavbar = () => {
        setNavBarActive(!navBarActive);
    }

    return (
        <div class="leading-normal tracking-normal text-white gradient scroll-smooth">
            { editMode &&
                <div className='fixed bottom-0 right-0 z-50'>
                    <button className='bg-green-500 rounded-lg p-4 block z-50 ml-auto text-white m-4' onClick={getCMSElements}>Änderungen speichern</button>
                </div>
            }
            {modalVisibility && <Modal showModal={showModal} img={modalImg} titel={modalTitel} text={modalText} list={modalList} price={modalPrice}/>}


            <nav id="header" className={!editMode ?`fixed w-full z-30 top-0 text-white`:`block w-full z-30 top-0 text-white`}>
                <div class="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
                    <div class="pl-4 flex items-center">
                        <a class="toggleColour text-white no-underline hover:no-underline font-bold text-2xl lg:text-4xl" href="#">
                            {scrollPosition < 10 && <img src={Logo} alt="logo" id="logo-one" className='cmsImage'/>}
                            {scrollPosition > 10 && <img src={LogoBlue} alt="logo" id="logo-two" className='cmsImage'/>}
                        </a>
                    </div>
                    <div class="block lg:hidden pr-4">
                        <button id="nav-toggle" class="flex items-center p-1 text-white hover:text-gray-900 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out" onClick={toggleNavbar}>
                            <svg class="h-6 w-6 fill-white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" id='navBurger'>
                            <title>Menu</title>
                            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                            </svg>
                        </button>
                    </div>
                    <div class={`w-full flex-grow lg:flex lg:items-center lg:w-auto mt-2 ${navBarActive ? 'block' : 'hidden'} lg:mt-0 bg-white lg:bg-transparent text-black p-4 lg:p-0 z-20`} id="nav-content">
                        <ul class="list-reset lg:flex justify-end flex-1 items-center">
                            <li class="mr-3">
                            <a class="inline-block py-2 px-4 text-black no-underline scroll-pt-10" href="#services">Services</a>
                            </li>
                            <li class="mr-3">
                            <a class="inline-block text-black no-underline hover:text-gray-800 hover:text-underline py-2 px-4" href="#references">Referenzen</a>
                            </li>
                            <li class="mr-3">
                            <a class="inline-block text-black no-underline hover:text-gray-800 hover:text-underline py-2 px-4" href="#about">Über Uns</a>
                            </li>
                            <li class="mr-3">
                            <a class="inline-block text-black no-underline hover:text-gray-800 hover:text-underline py-2 px-4" href="#contact">Kontakt</a>
                            </li>
                        </ul>
                    <button
                        id="navAction"
                        class="mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full mt-4 lg:mt-0 py-4 px-8 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
                    >
                        Termin buchen
                    </button>
                    </div>
                </div>
                <hr class="border-b border-gray-100 opacity-25 my-0 py-0" />
            </nav>
            <div class="pt-24">
            <div class="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
                <div class="flex flex-col w-full md:w-2/5 justify-center items-center text-center md:text-left">
                <p class="uppercase tracking-loose w-full text-center cmsText" id='heroSubtitel'>Ihr Computer hat ein Problem?</p>
                <h1 class="my-4 text-4xl font-bold leading-tight text-center cmsText" id='heroTitel'>
                    Gib deinem PC <br /> den Helden,<br /> den er verdient!
                </h1>
                <p class="leading-normal text-xl mb-8 text-center cmsText" id='heroText'>
                    Befreie dich von <br /> Computerproblemen für klare <br /> Prioritäten.
                </p>
                <a href="#services">
                    <button class="mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                        <p>Mehr erfahren</p>
                    </button>
                </a>
                </div>
                <div class="w-full md:w-3/5 py-6 text-center mb-10">
                <img class="w-full md:w-4/5 z-50" src={HeroImg} className='cmsImage' id='hero-img'/>
                </div>
            </div>
            </div>
            <div class="relative -mt-12 lg:-mt-24">
            <svg viewBox="0 0 1428 174" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g transform="translate(-2.000000, 44.000000)" fill="#FFFFFF" fill-rule="nonzero">
                    <path d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496" opacity="0.100000001"></path>
                    <path
                    d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                    opacity="0.100000001"
                    ></path>
                    <path d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z" id="Path-4" opacity="0.200000003"></path>
                </g>
                <g transform="translate(-4.000000, 76.000000)" fill="#FFFFFF" fill-rule="nonzero">
                    <path
                    d="M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,104.352 L1.121,104.031 L0.457,34.035 Z"
                    ></path>
                </g>
                </g>
            </svg>
            </div>
            <section class="bg-white border-b py-8 scroll-mt-8" id='services'>
            <div class="container mx-auto flex flex-wrap pt-4 pb-12 justify-center">
                <h2 class="w-full my-2 text-4xl font-bold leading-tight text-center text-gray-800 cmsText" id='serviceTitel'>
                Services
                </h2>
                <div class="w-full mb-4">
                    <div class="h-1 mx-auto gradient w-64 opacity-50 my-0 py-0 rounded-t"></div>
                </div>
                <ServiceContainer cmsServices={cmsServices} showModal={showModal}/>
            </div>
            </section>
            <section class="bg-white border-b py-8 scroll-mt-8" id='references'>
            <div class="container mx-auto flex flex-wrap pt-4 pb-12 justify-center w-full">
                <h2 class="w-full my-2 text-4xl font-bold leading-tight text-center text-gray-800 cmsText" id='referenceTitel'>
                    Referenzen
                </h2>
                <div class="w-full mb-4">
                    <div class="h-1 mx-auto gradient w-64 opacity-50 my-0 py-0 rounded-t"></div>
                </div>

                <ReferenceContainer cmsReferences={cmsReferences} showModal={showModal}/>

            </div>
            
            </section>
            <section class="bg-white border-b py-8 scroll-mt-8" id='about'>
            <div class="container mx-auto flex flex-wrap pt-4 pb-12 justify-center">
                <h2 class="w-full my-2 text-4xl font-bold leading-tight text-center text-gray-800 cmsText" id='aboutTitel'>
                Über Uns
                </h2>
                <div class="w-full mb-4">
                <div class="h-1 mx-auto gradient w-64 opacity-75 my-0 py-0 rounded-t"></div>
                </div>
                <div class="flex flex-col  m-6 my-0 p-4">
                <div>
                    <p class="text-gray-600 text-center cmsText mx-8 md:mx-24 2xl:mx-[4vw]" id='aboutText'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui tempora inventore sunt, corrupti ab dolorem optio aliquam iure. Ipsum, molestias? Fuga, sed laboriosam illo dolores iste minus itaque voluptas, dignissimos culpa autem odio sequi quibusdam! Exercitationem eligendi voluptatem veritatis sit rerum ab, velit, libero explicabo quaerat fuga, saepe minima ratione!</p>
                </div>
                <div class="flex flex-row items-center justify-end mt-10 gap-x-10 md:gap-x-16">
                    <div><p class="text-gray-600 font-bold text-lg cmsText whitespace-nowrap" id='aboutOwner'><span className='whitespace-nowrap'>Matthias Anliker</span> <br/>Inhaber</p></div>
                    <div><img src="https://placehold.co/150x150" alt="team-picture" className="rounded-full cmsImage w-full lg:w-auto h-auto md:h-[50%] max-h-md" id='owner-img'/></div>
                </div>
                </div>
            </div>
            </section>
            <section class="bg-white border-b py-8 scroll-mt-8">
            <div class="container mx-auto flex flex-wrap pt-4 pb-12 justify-center">
                <h2 class="w-full my-2 text-4xl font-bold leading-tight text-center text-gray-800 cmsText" id='customerTitel'>
                Was unsere <br/> Kunden sagen
                </h2>
                <div class="w-full mb-4">
                <div class="h-1 mx-auto gradient w-64 opacity-50 my-0 py-0 rounded-t"></div>
                </div>
                <div class="flex flex-col  m-6 my-0 p-4">
                <div>
                    <p class="text-gray-600 text-center cmsText" id='customerText'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Itaque quae eos illo quasi vel quibusdam!</p>
                </div>
                </div>
                <div class="w-[100vw] flex flex-col justify-center">
                    <div class="my-10 lg:mx-10 flex flex-row gap-x-6 overflow-x-scroll text-gray-600 snap-mandatory snap-x scroll-pl-6" id="reviews">
                        <Review />
                    </div>
                </div>
            </div>
            </section>
            <section class="bg-white py-8 scroll-mt-8" id='contact'>
            <div class="container mx-auto flex flex-wrap pt-4 pb-12 justify-center">
                <h2 class="w-full my-2 text-4xl font-bold leading-tight text-center text-gray-800 cmsText" id='contactTitel'>
                Kontakt
                </h2>
                <div class="w-full mb-4">
                <div class="h-1 mx-auto gradient w-64 opacity-50 my-0 py-0 rounded-t"></div>
                </div>
                <div class="flex flex-col  m-6 my-0 p-4">
                <div>
                    <p class="text-gray-600 text-center cmsText" id='contactText'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Itaque quae eos illo quasi vel quibusdam!</p>
                </div>

                <form onSubmit={submitForm} class="mx-8 md:mx-24 2xl:mx-[0vw] mt-4 lg:grid lg:grid-cols-2 lg:gap-x-14 lg:gap-y-16 animate__animated fadeInAni text-gray-600" id="form">
                    <div>
                        <p id="namelabel" class="mt-4 md:text-lg">* Name:</p>
                        <input type="text" required name="fname" id="fname" placeholder="Max Mustermann" class="mt-1 w-full h-[6vh] rounded-xl border-2 border-[#207BFF] mx-auto flex px-4" onChange={(e) => setFormFullname(e.target.value)} value={formFullname}/>
                        <p class="mt-3 md:text-lg">Telefonnummer:</p>
                        <input type="text" name="number" id="number" placeholder="+41 76 543 21 00" class="mt-1 w-full h-[6vh] rounded-xl border-2 border-[#207BFF] mx-auto flex px-4" onChange={(e) => setFormNumber(e.target.value)} value={formNumber}/>
                        <p class="mt-3 md:text-lg">* Email:</p>
                        <input type="text" name="mail" id="mail" placeholder="Max.Mustermann@mail.com" class="mt-1 w-full h-[6vh] rounded-xl border-2 border-[#207BFF] mx-auto flex px-4"onChange={(e) => setFormMail(e.target.value)} value={formMail}/>
                    </div>
                    <div>
                        <p class="mt-3 md:text-lg">* Nachricht:</p>
                        <textarea placeholder="Guten Tag! Ich hätte gerne eine unverbindliche Offerte für mein Unternehmen." name="message" id="message" cols="30" rows="10" class="mt-1 w-full h-[20vh] rounded-xl border-2 border-[#207BFF] mx-auto flex px-4 pt-3 md:text-lg" onChange={(e) => setFormMessage(e.target.value)} value={formMessage}></textarea>
                        {formSuccess === true && <p className='text-center mt-4 font-bold text-blue-600'>Vielen Dank für Ihre Anfrage. Ihr Nachricht wurde übermittelt!</p>}
                        {formSuccess === false && <p className='text-center mt-4 font-bold text-red-600'>Ups, etwas ist schiefgelaufen. Versuchen Sie es bitte erneut!</p>}
                        <input type="submit" class=" cursor-pointer gradient h-[12vw] md:h-[9vw] lg:h-[6vw] 2xl:h-[7vh] px-5 text-white rounded-xl font-semibold drop-shadow-lg mt-4 lg:mt-6 lg:px-8 whitespace-pre md:text-lg" id="submitbtn" ></input>
                    </div>
                </form>
                </div>
            </div>
            </section>
            <svg class="wave-top" viewBox="0 0 1439 147" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g transform="translate(-1.000000, -14.000000)" fill-rule="nonzero">
                <g class="wave" fill="#ffffff">
                    <path
                    d="M1440,84 C1383.555,64.3 1342.555,51.3 1317,45 C1259.5,30.824 1206.707,25.526 1169,22 C1129.711,18.326 1044.426,18.475 980,22 C954.25,23.409 922.25,26.742 884,32 C845.122,37.787 818.455,42.121 804,45 C776.833,50.41 728.136,61.77 713,65 C660.023,76.309 621.544,87.729 584,94 C517.525,105.104 484.525,106.438 429,108 C379.49,106.484 342.823,104.484 319,102 C278.571,97.783 231.737,88.736 205,84 C154.629,75.076 86.296,57.743 0,32 L0,0 L1440,0 L1440,84 Z"
                    ></path>
                </g>
                <g transform="translate(1.000000, 15.000000)" fill="#FFFFFF">
                    <g transform="translate(719.500000, 68.500000) rotate(-180.000000) translate(-719.500000, -68.500000) ">
                    <path d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496" opacity="0.100000001"></path>
                    <path
                        d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                        opacity="0.100000001"
                    ></path>
                    <path d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z" opacity="0.200000003"></path>
                    </g>
                </g>
                </g>
            </g>
            </svg>
            <section class="container mx-auto text-center py-12 mb-8 px-6">
            <h2 class="w-full my-2 text-3xl font-bold leading-tight text-center text-white cmsText" id='ctaTitel'>
                Buchen Sie heute noch den passenden Service für Ihr Problem
            </h2>
            <a href="#contact">
                <button class="mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                    Jetzt Termin buchen!
                </button>
            </a>
            </section>
            <footer class="bg-white">
            <div class="container mx-auto px-8 flex flex-col">
                <div class="w-full flex flex-col md:flex-row py-6">
                    <div class="flex-1 mb-6 text-black">
                        <a class="text-pink-600 no-underline hover:no-underline font-bold text-2xl lg:text-4xl" href="#">
                        <img src={LogoBlue} alt="logo" className='cmsImage' id='logo-two'/>
                        </a>
                    </div>
                    <div class="flex-1">
                        <p class="uppercase text-gray-500 md:mb-6">Legal</p>
                        <ul class="list-reset mb-6">
                        <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                            <a onClick={() => showModal('Impressum', false , ['Matthias Anliker','Riedgasse 20,', '3373 Heimenhausen,', 'info@anlikersupport.ch'], false, false)} class="no-underline hover:underline text-gray-800 hover:text-blue-500">Impressum</a>
                        </li>
                        </ul>
                    </div>
                    <div class="flex-1">
                        <p class="uppercase text-gray-500 md:mb-6">Social</p>
                        <ul class="list-reset mb-6">
                        <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                            <a href="#" class="no-underline hover:underline text-gray-800 hover:text-blue-500">Instagram</a>
                        </li>
                        <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                            <a href="#" class="no-underline hover:underline text-gray-800 hover:text-blue-500">Linkedin</a>
                        </li>
                        </ul>
                    </div>
                    <div class="flex-1">
                        <p class="uppercase text-gray-500 md:mb-6">Firma</p>
                        <ul class="list-reset mb-6">
                        <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                            <a href="#services" class="no-underline hover:underline text-gray-800 hover:text-blue-500">Services</a>
                        </li>
                        <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                            <a href="#references" class="no-underline hover:underline text-gray-800 hover:text-blue-500">Referenzen</a>
                        </li>
                        <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                            <a href="#about" class="no-underline hover:underline text-gray-800 hover:text-blue-500">Über Uns</a>
                        </li>
                        <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                            <a href="#contact" class="no-underline hover:underline text-gray-800 hover:text-blue-500">Kontakt</a>
                        </li>
                        </ul>
                    </div>
                </div>
                <div className='flex flex-col mx-auto'>
                        <a href="https://winistoerfer-webdesign.ch/" class="text-gray-800 flex justify-center" target='_blank'><p class="font-bold">Webdesign by Winistörfer Webdesign</p></a>
                        <p class="text-gray-800 justify-center flex">&#169; Anliker Support 2023</p>
                    </div>
            </div>
            </footer>
    </div>
  )
}

export default WebsitePreview