import React from 'react'
import {ReactComponent as StarFull} from './assets/svg/star-full.svg'
import {ReactComponent as StarEmpty} from './assets/svg/star-empty.svg'

const ReviewItem = ({reviewName, reviewText, reviewRating}) => {
    const createStars = (reviewStars) => {
        let starList = []
        for (let i = 0; i < reviewStars; i++) {
            starList.push(<StarFull />)
        }
        if (reviewStars < 5) {
            for (let i = 0; i < 5-reviewStars; i++) {
                starList.push(<StarEmpty />)
            }
        }
        return starList;
    }
  return (
    <div className="w-80 shadow-lg px-6 py-4 rounded-xl border-[#207BFF] border-2 min-w-[300px] snap-center snap-always">
        <p className="text-lg font-semibold">{reviewName}</p>
        <div className="flex items-center space-x-1 my-1">
            {createStars(reviewRating)}
        </div>
        <div>
            <p>{reviewText}</p>
        </div>
    </div>
  )
}

export default ReviewItem