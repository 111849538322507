import React, { useEffect, useState } from 'react'
import {Buffer} from 'buffer';
import ManageServiceContainer from '../manage-services-container/manage-services-container.component';
import Loading from '../loading/loading.component';

const ManageServices = () => {
  const [cmsServices, setCMSServices] = useState([])
  const [loading, setLoading] = useState(true)

  async function getServices() {
    fetch(process.env.REACT_APP_API_URL+'api/cms/get/services')
        .then(response => response.json())
        .then(data => {
            data.forEach(element => {
                const stringToArray = JSON.parse(element.list)
                element.list = stringToArray
            })
            setCMSServices(data)
            setLoading(false)
        })
}

  useEffect(() => {
    getServices()
  }, [])

  return (
    <div className='pl-6 mt-10 lg:w-[75vw]'>
        <div>
            <p className='font-bold text-xl'>Services verwalten</p>
            <p>In diesem Bereich kannst du deine vorhandenen Services löschen lassen.</p>
            <p>Um einen Service löschen zu lassen, klicke ganz einfach auf den Knopf 'Service löschen'</p>
            <div className='flex flex-wrap'>
              {!loading ? <ManageServiceContainer cmsServices={cmsServices} /> : <Loading/>}
            </div>
        </div>
    </div>
  )
}

export default ManageServices