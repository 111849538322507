import React, { useRef, useState, useEffect } from 'react'
import {Buffer} from 'buffer';
import { useNotificationContext } from '../../context/notification.context'

const WebsiteImageChange = () => {
  const [referenceImage, setReferenceImage] = useState('')
  const [referenceID, setReferenceID] = useState('')
  const { notification, setNotification } = useNotificationContext()

  const fileRef = useRef();

  const submitReference = async (event) => {
    event.preventDefault();
    const image = await convertToBase64(referenceImage)

    let reference = {}

    reference[referenceID] = image

    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reference)
    }
    // fetch('http://localhost:4111/api/cms/send/image', requestOptions)
    fetch(process.env.REACT_APP_API_URL+'api/cms/send/image', requestOptions)
        .then(response => {
            console.log(response)
            setNotification({
              show: true,
              color: 'green',
              text: 'Bild wurde erfolgreich hochgeladen!'
            })
        })
        .catch (error => {
          setNotification({
            show: true,
            color: 'red',
            text: 'Es ist ein Fehler aufgetreten! Versuche es später erneut.'
          })
        })

    fileRef.current.value = null;
    setReferenceID('')
}

  useEffect(() => {
    getCMSImages()
  }, [])

const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  async function getCMSImages() {
    // fetch('http://localhost:4111/api/cms/get/images')
    fetch(process.env.REACT_APP_API_URL+'api/cms/get/images')
    .then(response => response.json())
    .then(data => {
        console.log(data)
        data.forEach(element => {
            try {
            const base64string = Buffer.from(element.image.data, 'base64').toString('ascii')
            document.getElementById(element.id).src = base64string
            } catch (error) {
                console.log(error, element)
            }
        })
    })
}

  return (
    <div className='pl-6 mt-10'>
      <div>
        <p className='text-xl font-bold'>Website Bilder ändern</p>
        <p>Hier kannst du die Bilder von deiner Website ändern. <br/>Klicke hierfür auf Datei auswählen und wähle dein neues gewünschtes Bild aus.</p>
      </div>
      <div>
        <div className='flex flex-col lg:flex-row justify-center items-center border-2 border-blue-500 rounded-lg p-2 mt-10 bg-blue-300'>
          <div className='mr-6'>
            <img className='w-[100%] lg:w-[20vw]' src='' alt='logo1' id='logo-one' />
            <p className='text-white text-center mt-2 font-semibold text-lg'>Logo 1</p>
          </div>
          <form className="border-2 border-blue-500 p-4 rounded-xl max-w-xl flex flex-col" onSubmit={submitReference}>
                  <p className='mt-2 text-white font-semibold mb-2'>Neues Bild hochladen</p>
                  <input className='block w-full text-sm text-white border border-blue-400 rounded-lg cursor-pointer focus:outline-none bg-blue-500 placeholder-gray-400 mb-2' type="file" accept="image/*" onChange={e => {
                    console.log("Files", e.target.files[0])
                    setReferenceImage(e.target.files[0])
                    }} ref={fileRef} />
                <input value='Hochladen' type='submit' className='p-4 bg-blue-400 rounded-xl text-white cursor-pointer' onClick={() => setReferenceID('logo-one')}/>
            </form>
        </div>
        <div className='flex flex-col lg:flex-row justify-center items-center border-2 border-blue-500 rounded-lg p-2 mt-10 bg-blue-300'>
          <div className=' mr-6'>
            <img className='w-[100%] lg:w-[20vw]' src='' alt='logo1' id='logo-two' />
            <p className='text-white text-center mt-2 font-semibold text-lg'>Logo 2</p>
          </div>
          <form className="border-2 border-blue-500 p-4 rounded-xl max-w-xl flex flex-col" onSubmit={submitReference}>
                <p className='mt-2 text-white font-semibold mb-2'>Neues Bild hochladen</p>
                <input className='block w-full text-sm text-white border border-blue-400 rounded-lg cursor-pointer focus:outline-none bg-blue-500 placeholder-gray-400 mb-2' type="file" accept="image/*" onChange={e => {
                    console.log("Files", e.target.files[0])
                    setReferenceImage(e.target.files[0])
                    }} ref={fileRef}/>
                <input  value='Hochladen' type='submit' className='p-4 bg-blue-400 rounded-xl text-white cursor-pointer' onClick={() => setReferenceID('logo-two')}/>
            </form>
        </div>
        <div className='flex flex-col lg:flex-row justify-center items-center border-2 border-blue-500 rounded-lg p-2 mt-10 bg-blue-300'>
          <div className=' mr-6'>
            <img className='w-[100%] lg:w-[20vw]' src='' alt='hero-img' id='hero-img' />
            <p className='text-white text-center mt-2 font-semibold text-lg'>Startseite Bild</p>
          </div>
          <form className="border-2 border-blue-500 p-4 rounded-xl max-w-xl flex flex-col" onSubmit={submitReference}>
                <p className='mt-2 text-white font-semibold mb-2'>Neues Bild hochladen</p>
                <input className='block w-full text-sm text-white border border-blue-400 rounded-lg cursor-pointer focus:outline-none bg-blue-500 placeholder-gray-400 mb-2' type="file" accept="image/*" onChange={e => {
                    console.log("Files", e.target.files[0])
                    setReferenceImage(e.target.files[0])
                    }} ref={fileRef}/>
                <input value='Hochladen' type='submit' className='p-4 bg-blue-400 rounded-xl text-white cursor-pointer' onClick={() => setReferenceID('hero-img')}/>
            </form>
        </div>
        <div className='flex flex-col lg:flex-row justify-center items-center border-2 border-blue-500 rounded-lg p-2 mt-10 bg-blue-300'>
          <div className=' mr-6'>
            <img className='w-[100%] lg:w-[20vw]' src='' alt='owner-img' id='owner-img' />
            <p className='text-white text-center mt-2 font-semibold text-lg'>Besitzer Bild</p>
          </div>
          <form className="border-2 border-blue-500 p-4 rounded-xl max-w-xl flex flex-col" onSubmit={submitReference}>
                <p className='mt-2 text-white font-semibold mb-2'>Neues Bild hochladen</p>
                <input className='block w-full text-sm text-white border border-blue-400 rounded-lg cursor-pointer focus:outline-none bg-blue-500 placeholder-gray-400 mb-2' type="file" accept="image/*" onChange={e => {
                    console.log("Files", e.target.files[0])
                    setReferenceImage(e.target.files[0])
                    }}
                    ref={fileRef}
                />
                <input value='Hochladen' type='submit' className='p-4 bg-blue-400 rounded-xl text-white cursor-pointer' onClick={() => setReferenceID('owner-img')}/>
            </form>
        </div>
      </div>
    </div>
  )
}

export default WebsiteImageChange