import React, { useEffect } from 'react'
import './notification.styles.css'
import { useNotificationContext } from '../../context/notification.context'

const Notification = ({}) => {

    const { notification, setNotification } = useNotificationContext()

    function timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
    }

    const hideNotification = async () => {
        await timeout(6000)
        setNotification({
            ...notification,
            show: false
        })
    }

    useEffect(() => {
        hideNotification()
    }, [])
  return (
    <div className={`fixed top-0 w-[100vw] bg-${notification.color}-500 text-white text-xl`}>
        <div className='py-6 px-6'>
            <div><p>{notification.text}</p></div>
        </div>
        
        <div className='loading w-full p-1 bg-white'></div>
    </div>
  )
}

export default Notification