import React, {useState, useRef} from 'react'
import WebsiteEdit from '../../components/website-edit/website-edit.component'
import WebsiteTextChange from '../../components/website-text-change/website-text-change.component'
import CreateReference from '../../components/create-reference/create-reference.component'
import ManageReferences from '../../components/manage-references/manage-references.component'
import AdminNav from '../../components/admin-nav/admin-nav.component'
import WebsiteImageChange from '../../components/website-image-change/website-image-change.component'
import Notification from '../../components/notification/notification.component'
import { useAuth0 } from '@auth0/auth0-react'
import Login from '../login/login.component'
import Loading from '../../components/loading/loading.component'
import ChangePassword from '../../components/change-password/change-password.component'
import { useNotificationContext } from '../../context/notification.context'
import ManageServiceContainer from '../../components/manage-services-container/manage-services-container.component'
import ManageServices from '../../components/manage-services/manage-services.component'
import CreateService from '../../components/create-service/create-service.component'

const AdminPanel = () => {
    const { notification, setNotification } = useNotificationContext()

    const [navigation, setNavigation] = useState('websiteText')

    const ref = useRef();

    const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  return (
    <>
        {notification.show && <Notification/>}
        {
        isLoading 
        ? <div className='flex w-full h-[100vh] justify-center items-center text-center'>
            <Loading />
        </div>
        :
        isAuthenticated 
            ? 
            <div className='flex flex-col w-full'>
                <div className='flex flex-col mx-auto text-center bg-blue-600 w-full h-full py-10 border-b-4 border-white'>
                    <h3 className='text-5xl text-white font-bold'>Admin Panel</h3>
                    <p className='mt-4 font-semibold text-white'>Willkommen auf dem Admin Panel von deiner Website <br/>Hier kannst du den Inhalt deiner Website ganz einfach ändern.</p>
                </div>
                <div className='navigation flex flex-col lg:flex-row w-auto'>
                    <AdminNav setNavigation={setNavigation}/>
                    <div className=''>
                        {navigation === 'websiteText' &&
                            <WebsiteTextChange ref={ref} />
                        }
                        {navigation === 'websiteImages' &&
                            <WebsiteImageChange />
                        }
                        {navigation === 'websiteReferencesEdit' &&
                            <ManageReferences />
                        }
                        {navigation === 'websiteReferencesCreate' &&
                            <CreateReference />
                        }
                        {navigation === 'resetPassword' &&
                            <ChangePassword />
                        }
                        {navigation === 'websiteServicesEdit' &&
                            <ManageServices />
                        }
                        {navigation === 'websiteServicesCreate' &&
                            <CreateService />
                        }
                    </div>
                </div>
            </div>
            : 
            <>{loginWithRedirect()}</>
        }
    </>
  )
}

export default AdminPanel