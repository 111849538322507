import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import WebsitePreview from './routes/website-preview/website-preview.component';
import reportWebVitals from './reportWebVitals';
import AdminPanel from './routes/admin-panel/admin-panel.component';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Routes,
  BrowserRouter
} from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

import { NotificationContextProvider } from './context/notification.context';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="dev-6xpr41sqjtnuckdl.us.auth0.com"
      clientId="E9MOISUmZwYD60m8ApYAgf2ZkbPvIJpK"
      authorizationParams={{
        // redirect_uri: 'http://localhost:4040/admin'
        redirect_uri: 'https://anlikersupport.ch/admin'
      }}
    >
      <NotificationContextProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/">
              <Route index element={<WebsitePreview />} />
              <Route path="admin" element={<AdminPanel />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </NotificationContextProvider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();