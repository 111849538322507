import React, { useEffect, useState } from 'react'
import ReferenceItem from '../reference-item/reference-item.component'

const ReferenceContainer = ({ showModal, cmsReferences }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [referenceCount, setReferenceCount] = useState(0);

  const createReferences = () => {
    let i = 0;
    return cmsReferences && cmsReferences.map((reference, index) => {
      i++;
      if (i < 3) {
        return <ReferenceItem key={index} reference={reference} showModal={showModal} />;
      } else {
        return (
          !isCollapsed && <ReferenceItem key={index} reference={reference} showModal={showModal} />
        );
      }
    });
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    setReferenceCount(cmsReferences.length);
    console.log(referenceCount);
  }, [cmsReferences]);

  return (
    <>
      {cmsReferences && createReferences()}
      <div className="flex mx-auto items-center justify-center w-[100%]">
        {referenceCount > 2 &&
          <button
            className="mx-auto lg:mx-0 
                            hover:underline gradient text-white font-bold rounded-lg 
                            my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline 
                            transform transition hover:scale-105 duration-300 ease-in-out"
            onClick={() => toggleCollapse()}
          >
            {isCollapsed ? "Mehr anzeigen" : "Weniger anzeigen"}
          </button>
        }
      </div>
    </>
  );
};

export default ReferenceContainer;
