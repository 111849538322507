import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { useNotificationContext } from '../../context/notification.context'

const ServiceItem = ({service, showModal, manageable }) => {
  const [deleted, setDeleted] = useState(false)
  const [confirmation, setConfirmation] = useState(false)
  const { setNotification } = useNotificationContext()

  const deleteService = () => {
    const serviceElement = {
      id: service.id.toString()
    }
    console.log(service.id)
    fetch(process.env.REACT_APP_API_URL+'api/cms/delete/service', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(serviceElement)
    })
      .then(response => {
        response.json()
        setNotification({
          show: true,
          color: 'green',
          text: 'Referenz wurde erfolgreich gelöscht!'
        })
        setDeleted(true)
      })
      .catch(error => {
        setNotification({
          show: true,
          color: 'red',
          text: 'Ein Fehler ist aufgetreten! Versuche es später erneut.'
        })
      })
  }

  return (
    <>
    {!deleted &&
      <div class="m-6 p-4 shadow-[0px_5px_10px_3px_rgba(0,0,0,0.3)] max-w-md flex flex-col lg:w-[20%]" id={service.id}>
        <h3 class="text-2xl text-gray-800 font-bold leading-none mb-3 break-words">
            {service && service.titel}
        </h3>
        <p class="text-gray-600 mb-2">
            {service&& service.shortText}
        </p>
        <p className='mt-4 font-bold text-xl text-blue-500'>
          {service && service.price}
        </p>
        <div class="flex mx-auto items-center justify-center w-[100%] image-preview" onclick={() => console.log("hi")}>
            {!manageable && service && 
              <button class="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 
                            px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 
                            duration-300 ease-in-out"
                    onClick={() => showModal(service.titel, service.text, service.list, false, service.price)}
              >
                Mehr erfahren
              </button>
            }
          </div>
        {manageable &&
          <div className='mt-4'>
            { !confirmation &&
                <button onClick={() => setConfirmation(true)} className='text-sm bg-red-500 text-white py-3 px-3 rounded-lg'>Service löschen</button>
            }            
            { confirmation &&
              <div className='flex flex-col'>
                <p>Bist du dir sicher?</p>
                <div className='flex flex-col lg:flex-row'>
                  <button onClick={() => setConfirmation(false)} className='bg-red-500 text-white p-2 rounded-lg text-xs'>Abbrechen</button>
                  <button onClick={() => deleteService()} className='bg-blue-500 text-white p-2 rounded-lg text-xs mt-2 lg:mt-0 lg:ml-2'>Löschen</button>
                </div>
              </div>
            }
          </div>
        }
      </div>
    }
    </>
  )
}

export default ServiceItem
