import React, { useState } from 'react'
import axios from 'axios'
import { useNotificationContext } from '../../context/notification.context'

const ChangePassword = () => {
    const [email, setEmail] = useState('')
    const { setNotification } = useNotificationContext()
    const changePassword = () => {
        let options = {
            method: 'POST',
            url: 'https://dev-6xpr41sqjtnuckdl.us.auth0.com/dbconnections/change_password',
            headers: {'content-type': 'application/json'},
            data: {
              client_id: 'E9MOISUmZwYD60m8ApYAgf2ZkbPvIJpK',
              email: email,
              connection: 'Username-Password-Authentication'
            }
          };
          axios.request(options).then(function (response) {
            console.log(response.data);
            setEmail('')
            setNotification({
                show: true,
                color: 'green',
                text: 'Passwort wurde erfolgreich zurückgesetzt! Überprüfe deine Emails.'
            })
          }).catch(function (error) {
            console.error(error);
            setNotification({
                show: true,
                color: 'red',
                text: 'Ein Fehler ist aufgetreten! Versuche es später erneut.'
            })
          });
    }
  return (
    <div className='flex flex-col'>
      <div className='bg-red-500 flex w-[100vw] lg:w-[75vw] h-[15vh] text-white text-3xl font-bold text-center justify-center items-center'>⚠︎ Dangerzone ⚠︎</div>
      <div className='pl-6 pr-6 mt-10 lg:items-start items-center'>
        <h3 className='text-xl font-bold'>Passwort ändern</h3>
        <p>In diesem Bereich kannst du dein Admin-Passwort zurücksetzen. <br/>Gib hierfür deine E-Mail-Adresse für dein Konto im untenstehenden Feld ein. <br/>Anschlissen erhälst du eine Nachricht mit einem Link
          um dein Passwort zurücksetzen zu können.
        </p>
        <div className='mt-4 border-2 border-blue-500 flex flex-col p-4 mr-[20vw] md:mr-[40vw] rounded-lg'>
          <p>E-Mail:</p>
          <input className='border-2 border-blue-400 rounded-lg p-2 mt-2' type="email" onChange={(e) => setEmail(e.target.value)} value={email}/>
          <button className='bg-blue-500 mt-2 text-white px-6 py-4 rounded-lg font-bold' onClick={() => changePassword()}>Passwort ändern</button>
        </div>
      </div>
    </div>
  )
}

export default ChangePassword