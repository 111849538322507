import React, {useState} from 'react'
import WebsiteEdit from '../website-edit/website-edit.component'
import WebsitePreview from '../../routes/website-preview/website-preview.component'

function WebsiteTextChange({ref}) {
    const [showWebsitePreview, setShowWebsitePreview] = useState(true)
  return (
    <div className='mt-10 flex flex-col lg:w-[75vw] w-full overflow-x-scroll lg:pl-6 '>
        <div className='w-full pl-6 lg:pl-0'>
            <p className='font-bold text-xl'>Website Text ändern</p>
            <p>Hier kannst du die Texte von deiner Website ändern.</p>
            <p className='mt-2'>
                Zum ändern der Texte, klicke auf den entsprechenden Text und schon kannst du beginnen den neuen Text zu schreiben.
                <br/>Klicke anschliessend auf speichern.
            </p>
            <div className='flex flex-row gap-10'>
                {/* <button className='p-4 bg-blue-500 text-white rounded-xl mt-2' onClick={() => setShowWebsitePreview(!showWebsitePreview)}>
                    {showWebsitePreview ? "Weniger anzeigen" : "Mehr anzeigen"}
                </button> */}
            </div>
        </div>
        {showWebsitePreview &&
            <div className='mt-10 border-4 border-gray-800 rounded-lg flex flex-col lg:w-[80% w-full'>
                <WebsitePreview editMode={true} />
            </div>
        }
    </div>
  )
}

export default WebsiteTextChange