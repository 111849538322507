import React, { useEffect, useState } from 'react'
import ReferenceItem from '../reference-item/reference-item.component'

const ManageReferenceContainer = ({ showModal, cmsReferences }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const createReferences = () => {
    let i = 0;
    return cmsReferences && cmsReferences.map((reference, index) => {
      i++;
      if (i < 3) {
        return <ReferenceItem key={index} reference={reference} showModal={showModal} manageable={true} />;
      } else {
        return (
          <ReferenceItem key={index} reference={reference} showModal={showModal} manageable={true} />
        );
      }
    });
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <>
      {cmsReferences && createReferences()}
      <div className="flex mx-auto items-center justify-center w-[100%]">
      </div>
    </>
  );
};

export default ManageReferenceContainer;
