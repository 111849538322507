import React, { useEffect, useState } from 'react'
import {ReactComponent as StarFull} from './assets/svg/star-full.svg'
import {ReactComponent as StarEmpty} from './assets/svg/star-empty.svg'
import ReviewItem from '../review-item/review-item.component'

const Review = ({reviewerName, reviewText, reviewStars}) => {
    useEffect(() => {
        getReviews()
    }, [])

    const [reviews, setReviews] = useState(null)

    const getReviews = async () => {
        const businessDetails = await fetch(process.env.REACT_APP_API_URL+'api/getBusiness', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            fields: ["reviews"],
            placeID: 'ChIJ22EVfGQ6jkcRb7QGzGD_fTM'
            })
        })

        const content = await businessDetails.json()
        setReviews(content)
    }

    const createReview = () => {
        const reviewList = reviews.reviews
        let i = 0;
        if (i <= 5) {
            return reviewList && reviewList.map((review) => {
                i++;
                return <ReviewItem reviewName={review.author_name} reviewText={review.text} reviewRating={review.rating}/>
            })
        }
    }
  return (
    <>
        { reviews && createReview()}
    </>
  )
}

export default Review;