import React, {useState } from 'react'
import { useNotificationContext } from '../../context/notification.context'

const CreateReference = () => {
    const [referenceTitel, setReferenceTitel] = useState('')
    const [referenceText, setReferenceText] = useState('')
    const [referenceImage, setReferenceImage] = useState('')
    const { setNotification } = useNotificationContext()

    const submitReference = async (event) => {
        event.preventDefault();
        const image = await convertToBase64(referenceImage)
        let image2 = referenceImage.getBase74
        console.log(image)

        let reference = {
            id: `${Math.floor(Math.ceil(Math.random() * 10000))}-${referenceTitel.replace(/\s/g, '')}`,
            titel: referenceTitel,
            text: referenceText,
            image_file: image,
        }

        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(reference)
        }
        fetch(process.env.REACT_APP_API_URL+'api/cms/send/reference', requestOptions)
            .then(response => {
                console.log(response)
                setNotification({
                    show: true,
                    color: 'green',
                    text: 'Referenz wurde erfolgreich erstellt!'
                })
                setReferenceImage('')
                setReferenceText('')
                setReferenceTitel('')
            })
            .catch(error => {
                console.log(error)
                setNotification({
                    show: true,
                    color: 'red',
                    text: 'Ein Fehler ist aufgetreten! Versuche es später erneut.'
                })
            
            })
    }

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
      };


  return (
    <div>
        <div className='pl-6 mt-10'>
            <p className='font-bold text-xl'>Neue Referenz erstellen</p>
            <p>Hier kannst du neue Referenzen zu deiner Website hinzufügen.</p>
            <p className='mt-2'>
                Zum erstellen einer neuen Referenz, fülle alle Angaben unten aus und klicke auf erstellen.
            </p>
            <form className="border-2 mt-6 border-blue-500 p-4 rounded-xl max-w-xl flex flex-col" onSubmit={submitReference}>
                <p className='mt-2'>Titel</p>
                <input type="text" className='border-2 border-gray-800 rounded-lg p-2' onChange={e => setReferenceTitel(e.target.value)} value={referenceTitel}/>
                <p className='mt-2'>Text</p>
                <textarea name="message" id="message" cols="30" rows="10" className="mt-1 w-full h-[20vh] rounded-xl border-2 border-gray-800 mx-auto flex px-4 pt-3 md:text-lg" onChange={e => setReferenceText(e.target.value)} value={referenceText}></textarea>
                <p className='mt-2'>Bild hochladen</p>
                <input type="file" accept="image/*" onChange={e => {
                    console.log("Files", e.target.files[0])
                    setReferenceImage(e.target.files[0])
                    }} />
                <input value='Erstellen' type='submit' className='p-4 mt-2 bg-blue-400 text-white rounded-xl cursor-pointer'/>
            </form>
        </div>
    </div>
  )
}

export default CreateReference