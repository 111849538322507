import React, {useState } from 'react'
import { useNotificationContext } from '../../context/notification.context'

const CreateService = () => {
    const [serviceTitel, setServiceTitel] = useState('')
    const [serviceText, setServiceText] = useState('')
    const [serviceShortText, setServiceShortText] = useState('')
    const [serviceList, setServiceList] = useState('')
    const [servicePrice, setServicePrice] = useState('')
    const { setNotification } = useNotificationContext()

    const submitService = async (event) => {
        event.preventDefault();
        let listArray = serviceList.split(',').map(item => {
            return `"${item.trim()}"`
        })

        let service = {
            id: `${Math.floor(Math.ceil(Math.random() * 10000))}-${serviceTitel.replace(/\s/g, '')}`,
            titel: serviceTitel,
            text: serviceText,
            shortText: serviceShortText,
            price: servicePrice,
            list: `[${listArray}]`
        }

        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(service)
        }
        fetch(process.env.REACT_APP_API_URL+'api/cms/send/service', requestOptions)
            .then(response => {
                console.log(response)
                setNotification({
                    show: true,
                    color: 'green',
                    text: 'Service wurde erfolgreich erstellt!'
                })
                setServiceText('')
                setServiceTitel('')
                setServiceShortText('')
                setServiceList('')
                setServicePrice('')
            })
            .catch(error => {
                console.log(error)
                setNotification({
                    show: true,
                    color: 'red',
                    text: 'Ein Fehler ist aufgetreten! Versuche es später erneut.'
                })
            
            })
    }

  return (
    <div>
        <div className='pl-6 mt-10 flex flex-col items-center lg:items-start w-[100vw] lg:w-[75vw]'>
            <p className='font-bold text-xl'>Neuer Service erstellen</p>
            <p>Hier kannst du einen neuen Service zu deiner Website hinzufügen.</p>
            <p className='mt-2'>
                Zum erstellen eines neuen Services, fülle alle Angaben unten aus und klicke auf erstellen.
            </p>
            <form className="border-2 mt-6 border-blue-500 p-4 rounded-xl max-w-xl flex flex-col" onSubmit={submitService}>
                <div>
                    <p className='mt-2'>Titel</p>
                    <input type="text" className='border-2 border-gray-800 rounded-lg p-2' onChange={e => setServiceTitel(e.target.value)} value={serviceTitel}/>
                </div>
                <div>
                    <p className='mt-2'>Service-Beschreibung</p>
                    <textarea name="message" id="message" cols="30" rows="10" className="mt-1 w-full h-[20vh] rounded-xl border-2 border-gray-800 mx-auto flex px-4 pt-3 md:text-lg" onChange={e => setServiceText(e.target.value)} value={serviceText}></textarea>
                </div>
                <div>
                    <p className='mt-2'>Service-Kurzbeschreibung</p>
                    <textarea name="message" id="message2" cols="30" rows="10" className="mt-1 w-full h-[20vh] rounded-xl border-2 border-gray-800 mx-auto flex px-4 pt-3 md:text-lg" onChange={e => setServiceShortText(e.target.value)} value={serviceShortText}></textarea>
                </div>
                <div>
                    <p className='mt-2'>Service-Liste <span className='text-xs'>(zB: Computer konfiguration, Computer aufsetzen, Computer zusammenbauen)</span></p>
                    <textarea name="message" id="message3" cols="30" rows="10" className="mt-1 w-full h-[20vh] rounded-xl border-2 border-gray-800 mx-auto flex px-4 pt-3 md:text-lg" onChange={e => setServiceList(e.target.value)} value={serviceList}></textarea>
                </div>
                <div>
                    <p className='mt-2'>Service-Preis</p>
                    <input type="text" className='border-2 border-gray-800 rounded-lg p-2' onChange={e => setServicePrice(e.target.value)} value={servicePrice}/>
                </div>
                <div></div>
                <input value='Erstellen' type='submit' className='p-4 bg-blue-400 text-white rounded-xl mt-4'/>
            </form>
        </div>
    </div>
  )
}

export default CreateService