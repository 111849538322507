import React, { useEffect, useState } from 'react'
import ServiceItem from '../service-item/service-item.component';

const ServiceContainer = ({ showModal, cmsServices }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [serviceCount, setServiceCount] = useState(0);

  const createReferences = () => {
    let i = 0;
    return cmsServices && cmsServices.map((service, index) => {
      i++;
      if (i < 5) {
        return <ServiceItem key={index} service={service} showModal={showModal} />;
      } else {
        return (
          !isCollapsed && <ServiceItem key={index} service={service} showModal={showModal} />
        );
      }
    });
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    setServiceCount(cmsServices.length);
    console.log(serviceCount);
  }, [cmsServices]);

  return (
    <>
      {cmsServices && createReferences()}
      <div className="flex mx-auto items-center justify-center w-[100%]">
        {serviceCount > 4 &&
          <button
            className="mx-auto lg:mx-0 
                            hover:underline gradient text-white font-bold rounded-lg 
                            my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline 
                            transform transition hover:scale-105 duration-300 ease-in-out"
            onClick={() => toggleCollapse()}
          >
            {isCollapsed ? "Mehr anzeigen" : "Weniger anzeigen"}
          </button>
        }
      </div>
    </>
  );
};

export default ServiceContainer;
