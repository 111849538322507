import React, { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

const AdminNav = ({setNavigation}) => {
    const [referenceCollapsed, setReferenceCollapsed] = useState(false)
    const [serviceCollapsed, setServiceCollapsed] = useState(false)

    const { logout } = useAuth0();
  return (
    <div className='w-full justify-center items-center text-center lg:justify-start lg:items-start lg:text-left lg:w-[20vw] lg:h-[100vh] bg-blue-600 flex flex-col p-10 select-none'>
        <h2 className='text-white text-2xl font-semibold'>Navigation</h2>
        <ul className='text-white text-lg gap-y-6 mt-4'>
            <li className="cursor-pointer" onClick={() => setNavigation('websiteText')}>Website Text ändern</li>
            <li className="cursor-pointer" onClick={() => setNavigation('websiteImages')}>Website Bilder ändern</li>
            <li className="cursor-pointer" onClick={() => setReferenceCollapsed(!referenceCollapsed)}>Referenzen <span className='text-3xl '>{referenceCollapsed ? '▾' : '▸'}</span></li>
                {referenceCollapsed && <li onClick={() => setNavigation('websiteReferencesEdit')} className='ml-6 cursor-pointer'>Referenzen verwalten</li>}
                {referenceCollapsed && <li onClick={() => setNavigation('websiteReferencesCreate')} className='ml-6 cursor-pointer'>Referenzen erstellen</li>}
            <li className="cursor-pointer" onClick={() => setServiceCollapsed(!serviceCollapsed)}>Services <span className='text-3xl'>{serviceCollapsed ? '▾' : '▸'}</span></li>
                {serviceCollapsed &&<li onClick={() => setNavigation('websiteServicesEdit')} className='ml-6 cursor-pointer'>Services verwalten</li>}
                {serviceCollapsed &&<li onClick={() => setNavigation('websiteServicesCreate')} className='ml-6 cursor-pointer'>Services erstellen</li>}
            <li className="cursor-pointer mt-6" onClick={() => setNavigation('resetPassword')}>Passwort zurücksetzen</li>
            <li><a href="/"><button className='py-4 px-6 bg-white text-blue-500 font-bold rounded-lg mt-10'>Zurück zur Website</button></a></li>
            <li><button className='py-4 px-6 bg-white text-blue-500 font-bold rounded-lg mt-10' onClick={() => logout({logoutParams: { returnTo: window.location.origin }})}>Log Out</button></li>
        </ul>
    </div>
  )
}

export default AdminNav