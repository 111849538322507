import React, { useEffect, useState } from 'react'
import ReferenceContainer from '../reference-container/reference-container.component'
import {Buffer} from 'buffer';
import ManageReferenceContainer from '../manage-references-container/manage-references-container.component';
import Loading from '../loading/loading.component';

const ManageReferences = () => {
  const [cmsReferences, setCMSReferences] = useState([])
  const [loading, setLoading] = useState(true)

  const getReferences = () => {
    fetch(process.env.REACT_APP_API_URL+'api/cms/get/references')
        .then(response => response.json())
        .then(data => {
            data.forEach(element => {
                const base64string = Buffer.from(element.image_file.data, 'base64').toString('ascii')
                element.image_file = `${base64string}`;
            })
            setCMSReferences(data)
            setLoading(false)
        })
  }

  useEffect(() => {
    getReferences()
  }, [])

  return (
    <div className='pl-6 mt-10 lg:w-[75vw]'>
        <div>
            <p className='font-bold text-xl'>Referenzen verwalten</p>
            <p>In diesem Bereich kannst du deine vorhandenen Referenzen löschen lassen.</p>
            <p>Um eine Referenz löschen zu lassen, klicke ganz einfach auf den Knopf 'Referenz löschen'</p>
            <div className='flex flex-wrap'>
              {!loading ? <ManageReferenceContainer cmsReferences={cmsReferences} /> : <Loading/>}
            </div>
        </div>
    </div>
  )
}

export default ManageReferences