import { createContext, useContext, useState } from 'react'

const NotificationContext = createContext({});

export const NotificationContextProvider = ({ children }) => {

    const [notification, setNotification] = useState({
        show: false,
        color: 'green',
        text: 'Erfolgreich!!!'
    })

    return <NotificationContext.Provider value={{notification, setNotification}}>{children}</NotificationContext.Provider>
}

export const useNotificationContext = () => useContext(NotificationContext)