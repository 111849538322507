import React from 'react'

const Modal = ({img, text, titel, showModal, list, price}) => {
  return (
    <div className="w-full h-full bg-black/40 fixed z-50 flex flex-col justify-center items-center" onClick={() => showModal()}>
        <div className="flex flex-col bg-gray-100 p-4">
          <div className={text && 'p-8'}>
            {titel && <h3 className='text-[#207BFF] text-2xl font-bold'>{titel}</h3>}
            {text && <p className='text-gray-800'>{text}</p>}
            {list && 
              <div className='text-gray-800 ml-6 mt-4'>
                <ul>
                  {list.map((item) => <li className='list-disc'>{item}</li>)}
                </ul>
              </div>
            }
            {price && <p className='text-gray-800 text-xl font-bold mt-6'>{price} CHF</p>}
          </div>
            {img && <img className='w-[90vw] md:w-[75vw]' src={img} alt="placeholder" />}
        </div>
    </div>
  )
}

export default Modal